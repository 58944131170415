
import About from "./Components/About";
import Blog from "./Components/Blog";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Header from "./Components/Header"
import Home from "./Components/Home";
import Service from "./Components/Service";

function App() {
  return (
    <div className="App">

      <Header />
      <Home />
      <About />
      <Service />
      <Blog />
      <Contact />
      <Footer />

    </div>
  );
}

export default App;
